import { ButtonIcon } from '@/components/shared/buttons/button-icon';
import { Link } from '@/components/shared/element/link';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import Backdrop from '@/components/shared/overlays/backdrop';
import { removeSpan } from '@/components/shared/utility/format';
import { CSS, styled } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';
import { IMy24Theme, IMy24Tracking } from '@/types/shared';

export const StyledMenuToggle = styled(Box, {});

const StyledCustomHamburgerLine = styled(Box, {
  transformOrigin: 'right center',
  transition: 'transform 0.45s cubic-bezier(0.475, 0.425, 0, 0.995) 0s',
  '&:nth-child(1)': {
    top: 0,
    transform: `scaleX(${16 / 20})`,
  },
  '&:nth-child(2)': {
    top: 6,
  },
  '&:nth-child(3)': {
    top: 12,
    transform: `scaleX(${12 / 20})`,
  },
});

export const StyledToggleButton = styled(Box, {
  appearance: 'none',
  outline: 'none',
  width: '$size-12',
  height: '$size-12',
  opacity: 0,
  transform: 'translateX(100px)',
  transition:
    'background-color 0.5s cubic-bezier(0.5, 0, 0, 0.75), opacity 0.5s cubic-bezier(0.5, 0, 0, 0.75), transform 0.5s cubic-bezier(0.5, 0, 0, 0.75)',
  '& *': {
    pointerEvents: 'none',
  },
  right: '$space-4',
  bottom: '$space-6',
  '@lg': {
    right: '$space-10',
    bottom: '$space-5',
  },
  '@hover': {
    '&:hover': {
      bc: '$gray700',
      [`& ${StyledCustomHamburgerLine}`]: {
        transform: 'scaleX(1)',
      },
    },
  },
  variants: {
    show: {
      true: {
        opacity: 1,
        pointerEvents: 'all',
        transform: 'translateX(0)',
      },
    },
    open: {
      true: {
        bc: '$transparent',
        boxShadow: 'none',
      },
    },
  },
});

const StyledCustomHamburger = styled(Box, {
  width: 18,
  height: 14,
  '@lg': {
    width: 20,
  },
  variants: {
    open: {
      true: {
        [`& ${StyledCustomHamburgerLine}`]: {
          backgroundColor: '$white',
          transformOrigin: 'center center',
          '&:nth-child(1)': {
            top: 0,
            transform: 'scaleX(1) translateY(6px) rotate(-45deg)',
          },
          '&:nth-child(2)': {
            top: 6,
            width: 0,
          },
          '&:nth-child(3)': {
            top: 12,
            transform: 'scaleX(1) translateY(-6px) rotate(45deg)',
          },
        },
      },
    },
  },
});

const StyledMenuPanel = styled(Box, {
  bottom: '$space-20',
  right: 0,
  opacity: 0,
  transform: 'translateY(16px)',
  transition:
    'opacity 0.5s cubic-bezier(0.5, 0, 0, 0.75), transform 0.5s cubic-bezier(0.5, 0, 0, 0.75)',
  width: '$size-w-screen',

  '@lg': {
    right: '$space-6',
    bottom: '$space-2',
    paddingBottom: '$space-20',
    paddingTop: '$space-8',
    borderRadius: '$rounded-4',
    width: '260px',
    backgroundColor: '$backdrop70',
    backdropFilter: 'blur(var(--blur-large))',
    willChange: 'backdrop-filter',
  },

  variants: {
    open: {
      true: {
        opacity: 1,
        pointerEvents: 'all',
        transform: 'translateY(0)',
      },
    },
  },
});

const StyledMenuLink = styled(Link, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  columnGap: '$space-3',
  py: '$space-3',
  us: 'none',
  height: '$size-12',
  '&:before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    bc: '$white',
    pointerEvents: 'none',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  paddingLeft: '$space-5',
  paddingRight: '$space-5',

  '&:hover': {
    '&:before': {
      opacity: 0.15,
    },
    [`${Text}`]: {
      fontFamily: '$bold',
      transform: 'translate(0px, 0.5px)',
    },
  },
});

interface FloatingMenuToggleProps {
  css?: CSS;
  className?: string;
  theme?: IMy24Theme;
  menu: IMenu[];
  show?: boolean;
  open?: boolean;
  uspModal?: boolean;
  onClickMenu?: () => void;
  dataTest?: string;
  dataTrack?: IMy24Tracking;
}

export const FloatingMenuToggle: React.FC<FloatingMenuToggleProps> = ({
  css,
  className,
  menu,
  show,
  open,
  uspModal,
  onClickMenu,
  dataTest = 'floatingMenu',
}) => {
  return (
    <StyledMenuToggle css={css} className={className}>
      <Backdrop
        open={open}
        closeOnBackdrop
        data-test={`${dataTest}_overlay`}
        mounted
        css={{
          zIndex: 0,
          opacity: open ? 1 : 0,
          pointerEvents: open ? 'all' : 'none',
          transition: 'opacity 0.5s cubic-bezier(0.5, 0, 0, 0.75)',
          '@lg': {
            display: 'none',
          },
        }}
        uspModal={uspModal}
      />
      <StyledMenuPanel
        position="absolute"
        pointerEvents="none"
        open={open}
        test={{
          dataTest: `${dataTest}_lists`,
        }}
      >
        <Box display="flex" direction="column" rowGap="1">
          {menu?.map((o, i) => (
            <StyledMenuLink
              key={i}
              href={o?.url}
              target={o?.target ?? undefined}
              tracking={{
                dataTrack: `floating-menu`,
                dataTrackUrl: o?.url,
                dataTrackText: removeSpan(o?.label),
              }}
              test={{
                dataTest: `${dataTest}_link`,
              }}
            >
              <Text
                as="p"
                size="sm"
                variant="text-white"
                wrap
                dangerouslySetInnerHTML={{ __html: o?.label }}
                data-test={`${dataTest}_title`}
                data-title={removeSpan(o?.label)}
              />
              {!!o?.icon && (
                <ButtonIcon
                  icon={o?.icon}
                  variant="button-icon-color-white"
                  css={{
                    pointerEvents: 'none',
                  }}
                />
              )}
            </StyledMenuLink>
          ))}
        </Box>
      </StyledMenuPanel>

      <StyledToggleButton
        component="button"
        ariaLabel="Menu"
        position="absolute"
        rounded="1"
        backgroundColor="gray800"
        boxShadow="100"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        pointerEvents="none"
        cursor="pointer"
        border="none"
        show={show}
        open={open}
        onClick={onClickMenu}
        test={{
          dataTest: `${dataTest}_button`,
        }}
      >
        <StyledCustomHamburger position="relative" fontSize="0" open={open}>
          <StyledCustomHamburgerLine
            display="block"
            position="absolute"
            right="0"
            width="5"
            height="0-5"
            backgroundColor="white"
          />
          <StyledCustomHamburgerLine
            display="block"
            position="absolute"
            right="0"
            width="5"
            height="0-5"
            backgroundColor="white"
          />
          <StyledCustomHamburgerLine
            display="block"
            position="absolute"
            right="0"
            width="5"
            height="0-5"
            backgroundColor="white"
          />
        </StyledCustomHamburger>
      </StyledToggleButton>
    </StyledMenuToggle>
  );
};
FloatingMenuToggle.displayName = 'FloatingMenuToggle';
