import { Box } from '@/components/shared/layout/box';
import { StyledToggleButton } from '@/components/shared/navigation/floating-menu/toggle';
import { styled } from '@/stitches.config';

export const StyledDefaultFloatingMenuWrapper = styled(Box, {
  position: 'fixed',
  fontSize: 0,
  right: 0,
  bottom: 0,
  zIndex: '$floating',
  variants: {
    open: {
      true: {
        [`${StyledToggleButton}`]: {
          transform: 'translateX(0) !important',
          opacity: '1 !important',
          pe: 'all !important',
        },
      },
      false: {},
    },
  },
});

export const StyledUspPopupFloatingMenuWrapper = styled(Box, {});
