import type { CSS } from '@stitches/react';
import { useCallback, useEffect } from 'react';

import {
  clearBodyLockScroll,
  enableBodyLockScroll,
} from '@/components/shared/utility/lock-scroll';
import { styled } from '@/stitches.config';

const BackdropStyled = styled('div', {
  position: 'fixed',
  inset: 0,
  bc: '$backdrop70',
  pe: 'all',
  overflow: 'auto',
  zIndex: '$popup',
  variants: {
    blur: {
      true: {
        backdropFilter: 'blur(var(--blur-large))',
        willChange: 'backdrop-filter',
      },
    },
  },
  defaultVariants: {
    blur: true,
  },
});

interface BackdropProps {
  /**
   * The class name for the backdrop.
   */
  className?: string;
  /**
   * The CSS for the backdrop.
   */
  css?: CSS;
  /**
   * Whether the backdrop is open.
   */
  open?: boolean;
  /**
   * The function to call when the backdrop is closed.
   */
  handleClose?: () => void;
  /**
   * Whether to lock the scroll when the backdrop is active.
   */
  lockScroll?: boolean;
  /**
   * Whether to close the modal when the ESC key is pressed.
   */
  keyboard?: boolean;
  /**
   * The content to display within the backdrop.
   */
  children?: React.ReactNode;
  /**
   * Whether the backdrop is closeable and can be closed by user interaction.
   */
  closeOnBackdrop?: boolean;
  /**
   * Whether to apply a blur effect to the backdrop.
   */
  blur?: boolean;
  /**
   * The data-test attribute for testing purposes.
   */
  dataTest?: string;
  mounted?: boolean;
  uspModal?: boolean;
}

const Backdrop: React.FC<BackdropProps> = ({
  className,
  css,
  open = true,
  closeOnBackdrop = true,
  lockScroll = true,
  keyboard = true,
  blur,
  children,
  handleClose,
  dataTest = 'backdrop',
  mounted,
  uspModal,
}: BackdropProps) => {
  const onCloseByKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (open && closeOnBackdrop && event.key === 'Escape') {
        handleClose?.();
        clearBodyLockScroll();
      }
    },
    [closeOnBackdrop, open, handleClose]
  );

  const onHandleClose = useCallback(() => {
    if (closeOnBackdrop && handleClose) {
      handleClose?.();
      clearBodyLockScroll();
    }
  }, [closeOnBackdrop, handleClose]);

  useEffect(() => {
    if (!keyboard || !closeOnBackdrop) return;
    document.addEventListener('keydown', onCloseByKeyboard);
    return () => {
      document.removeEventListener('keydown', onCloseByKeyboard);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboard, open, closeOnBackdrop]);

  useEffect(() => {
    if (uspModal) return;
    if (lockScroll && open) {
      return enableBodyLockScroll({ onlyOverflow: true });
    }
    clearBodyLockScroll();
  }, [lockScroll, open, uspModal]);

  return (
    (mounted || open) && (
      <BackdropStyled
        id="backdrop"
        css={css}
        className={className}
        blur={blur}
        onClick={onHandleClose}
        data-test={dataTest}
      >
        {children}
      </BackdropStyled>
    )
  );
};

Backdrop.displayName = 'Backdrop';

export default Backdrop;
