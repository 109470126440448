import React, { useEffect, useRef, useState } from 'react';

import { useMediaQuery } from '@/components/hooks/useMediaQuery';
import { StyledDefaultFloatingMenuWrapper } from '@/components/shared/navigation/floating-menu/FloatingMenu.styles';
import { FloatingMenuToggle } from '@/components/shared/navigation/floating-menu/toggle';
import {
  clearBodyLockScroll,
  enableBodyLockScroll,
} from '@/components/shared/utility/lock-scroll';
import { config, CSS } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

interface IMY24FloatingBuyingTools {
  menu?: IMenu[];
  css?: CSS;
  show?: boolean;
  uspModal?: boolean;
}

const View: React.FunctionComponent<IMY24FloatingBuyingTools> = ({
  menu = [],
  css,
  show,
  uspModal,
}: IMY24FloatingBuyingTools) => {
  const FLOATING_REF = useRef<HTMLDivElement>(null);
  const [togglePanel, setTogglePanel] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean | undefined>(false);

  const isMobile = useMediaQuery(config.media.maxsm);

  useEffect(() => {
    setShowMenu(show);
  }, [show]);

  useEffect(() => {
    if (show !== undefined) return;

    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
        setTogglePanel(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableScrolling = (e: Event) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (!FLOATING_REF.current) return;

    if (isMobile) {
      if (togglePanel) {
        enableBodyLockScroll();
        FLOATING_REF.current.addEventListener('pointermove', disableScrolling, {
          passive: false,
        });
        FLOATING_REF.current.addEventListener('touchmove', disableScrolling, {
          passive: false,
        });
      } else if (!uspModal) {
        clearBodyLockScroll();
      }
    }

    return () => {
      if (!FLOATING_REF.current) return;
      FLOATING_REF.current.removeEventListener(
        'pointermove',
        disableScrolling,
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          passive: false,
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      FLOATING_REF.current.removeEventListener('touchmove', disableScrolling, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        passive: false,
      });
    };
  }, [togglePanel, isMobile, uspModal]);

  return (
    <StyledDefaultFloatingMenuWrapper
      id="default-floating-menu"
      ref={FLOATING_REF}
      test={{
        dataTest: 'floatingMenu',
      }}
      open={togglePanel}
      css={css}
      data-myi-hide
    >
      <FloatingMenuToggle
        menu={menu}
        show={showMenu}
        open={togglePanel}
        uspModal={uspModal}
        onClickMenu={() => setTogglePanel(!togglePanel)}
        dataTest="floatingMenu"
      />
    </StyledDefaultFloatingMenuWrapper>
  );
};
View.displayName = 'SectionMY24SharedFloatingBuyingTools';
export default View;
