import React from 'react';

import Footer from '@/components/layouts/footer';
import FloatingMenu from '@/components/shared/navigation/floating-menu';
import {
  COPYRIGHT,
  MENU_LCV_ABOUT,
  MENU_LCV_BUYING_TOOLS,
  MENU_LCV_CONTACT,
  MENU_LCV_CONTENT_HUB_GROUP,
  MENU_LCV_MASTEROFALLROADS,
  MENU_LCV_MODEL_CV_FOOTER,
  MENU_LCV_MODEL_FOOTER,
  MENU_LCV_MODEL_FOOTER_2024,
  MENU_LCV_OTHER_SERVICE,
  MENU_LCV_PRIVILEGES,
  MENU_LCV_SERVICE,
  MENU_LCV_SOCIAL,
  MENU_LCV_SPECIAL_OFFER_AND_FINANCE,
} from '@/contents/lcv/link';

const FooterLCVGroup: React.FC = () => (
  <>
    <Footer
      web="lcv"
      copyright={COPYRIGHT}
      buyingToolMenu={MENU_LCV_BUYING_TOOLS}
      communicationChannels={MENU_LCV_CONTACT}
      carModelMenu={MENU_LCV_MODEL_FOOTER}
      carModelMenu2024={MENU_LCV_MODEL_FOOTER_2024}
      carCVModelMenu={MENU_LCV_MODEL_CV_FOOTER}
      serviceMenu={MENU_LCV_SERVICE}
      offerMenu={MENU_LCV_SPECIAL_OFFER_AND_FINANCE}
      privilegeMenu={MENU_LCV_PRIVILEGES}
      contentHubMenu={MENU_LCV_CONTENT_HUB_GROUP}
      otherServiceMenu={MENU_LCV_OTHER_SERVICE}
      aboutMenu={MENU_LCV_ABOUT}
      socialMenu={MENU_LCV_SOCIAL}
      masterofallroadsMenu={MENU_LCV_MASTEROFALLROADS}
    />
    <FloatingMenu menu={MENU_LCV_BUYING_TOOLS} />
  </>
);
FooterLCVGroup.displayName = 'FooterLCVGroup';
export default FooterLCVGroup;
